<template>
  <div class="shopinfo">
    <van-nav-bar
      title="店铺资料"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="main">
      <van-form @submit="onSubmit" ref="shopinfo">
        <van-field
          v-model="shopinfo.logo"
          name="logo"
          label="店铺LOGO"
          placeholder="店铺LOGO"
          is-link
          center
          :rules="[{ required: true, message: '请填写店铺LOGO' }]"
        >
          <template #input>
            <van-uploader
              v-model="uploader"
              :max-count="1"
              :after-read="logoupload"
              :before-delete="logodelete"
            />
          </template>
        </van-field>
        <van-field
          v-model="shopinfo.merchname"
          name="merchname"
          label="店铺名称"
          placeholder="店铺名称"
          is-link
          :rules="[{ required: true, message: '请填写店铺名称' }]"
        />
        <van-field
          v-model="shopinfo.bianhao"
          name="bianhao"
          label="店铺编号"
          placeholder="店铺编号"
          is-link
          readonly
          :rules="[{ required: true, message: '请填写店铺编号' }]"
        />
        <van-field
          v-model="shopinfo.desc"
          name="desc"
          label="店铺简介"
          placeholder="店铺简介"
          is-link
        />
        <van-field
          v-model="shopinfo.groupname"
          name="groupname"
          label="店铺类型"
          placeholder="店铺类型"
          style="margin-top: 10px;"
          is-link
          readonly
          v-if="false"
        />
        <van-field
          v-model="shopinfo.salecate"
          name="salecate"
          label="主营类目"
          placeholder="主营类目"
          is-link
          :rules="[{ required: true, message: '请填写主营类目' }]"
        />
        <!-- <van-field
          v-model="shopinfo.mobile"
          name="mobile"
          label="绑定手机"
          placeholder="绑定手机号"
          style="margin-top: 10px;"
          readonly
        /> -->
        <div style="margin: 16px;">
          <van-button round block type="danger" :loading="btnload" :native-type="btnload?'button':'submit'">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "Shopinfo",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {}, // 用户信息
      uploader: [], // 上传文件
      shopinfo: {
        logo: "",
        merchname: "",
        bianhao: "",
        desc: "",
        groupid: "",
        groupname: "",
        salecate: "",
        mobile: "",
      },
      btnload: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            that.shopinfo = res.data.data;
            if(that.shopinfo.groupid==1) {
              that.shopinfo.groupname = '联盟店';
            }else if(that.shopinfo.groupid==12) {
              that.shopinfo.groupname = '独立商城';
            }else if(that.shopinfo.groupid==13) {
              that.shopinfo.groupname = '供货分仓';
            }
            if (that.shopinfo.logo) {
              that.uploader.push({
                url: that.shopinfo.logo,
                isImage: true
              });
              console.log(that.uploader);
            }else {
              that.uploader = [];
            }
            console.log(that.info);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    // logo上传
    logoupload(file, detail) {
      file.status = 'uploading';
      file.message = '上传中...';
      console.log(file, detail);
      let that = null;
      that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/merch/logo/');

      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        if (response.data && response.data.code == 100000) {
          file.status = 'done';
          file.message = '上传完成';
          that.shopinfo.logo = response.data.img_url
        }
      }).catch( error =>{
        console.log(error);
        file.status = 'failed';
        file.message = '上传失败';
      })
    },
    // 删除头像
    logodelete () {
      this.uploader = [];
      this.shopinfo.logo = "";
    },
    // 保存按钮
    onSubmit() {
      console.log("submit");
      let that = null;
      that = this;
      if (!that.shopinfo.logo) {
        that.$toast("请上传logo");
        return false;
      }
      that.btnload = true;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/user/set_info",
          that.$qs.stringify({
            logo: that.shopinfo.logo,
            salecate: that.shopinfo.salecate,
            mobile: that.shopinfo.mobile,
            desc: that.shopinfo.desc,
            merchname: that.shopinfo.merchname
          })
        )
        .then(res => {
          console.log(res);
          that.btnload = false;
          if (res.data.code == 100000) {
            that.$toast(res.data.msg ? res.data.msg : "保存成功");
            setTimeout(() => {
              that.onClickLeft();
            }, 1500);
          }else {
            that.$toast(res.data.msg ? res.data.msg : "保存失败");
          }
        })
        .catch(err => {
          console.log(err);
          that.btnload = false;
        })
    }
  },
}
</script>
<style lang="less">
.van-uploader__preview-delete{
  width: 20px;
  height: 20px;
  border-radius: 0 0 0 16px;
}
.van-uploader__preview-delete-icon{
  font-size: 20px;
}
.shopinfo {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  .main {
    overflow: hidden;
    width: 100%;
    .van-cell {
      line-height: 30px;
      .van-cell__title {
        width: 75px;
      }
      .van-cell__value {
        flex: 1;
        .van-field__control {
          text-align: right;
          justify-content: flex-end;
          .van-uploader__upload {
            margin: 0;
            border-radius: 50%;
            overflow: hidden;
          }
          .van-uploader__preview {
            margin: 0;
            .van-uploader__preview-image {
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
      .van-cell__right-icon {
        line-height: 30px;
      }
      .van-field__error-message {
        text-align: right;
      }
    }
  }
}
</style>